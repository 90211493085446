.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  transition: all 0.25s ease-in-out;

  a {
    color: white;
    text-decoration: none;
    padding-top: 0;
    transition: all 0.25s ease-in-out;
    &:hover {
      transform: none !important;
    }
  }

  h1 {
    margin: 0;
    font-size: 64px;
  }
  h2 {
    margin: 0;
    font-weight: normal;
  }
}

.App-main {
  min-height: 40vh;
  background-color: #282c34;
  max-width: 800px;
  margin: auto;
}

.App-link {
  color: #61dafb;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .Circular-image { 
    transition: all .2s linear; 
    object-position: center;
    object-fit: contain;
    padding: 12px;
    margin: 0 12px;
    border-radius: 80px;
    width: 100px;
    height: 100px;
    background: white;
    cursor: pointer;
  }
  .Circular-image:hover {
    transform: scale(1.1);
  }
}

.Home {
  main {
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
  }
  a, a:hover, a:visited, a:active {
    font-size: 1.2rem;
    color: white;
    text-decoration: none;
    margin: 0 24px;

    @media screen and (max-width: 768px) {
      margin: 12px 0;
  }
  }
  a:hover {
    transform: scale(1.1);
  }
}

// Home
.Home {
  .App-header {
    min-height: 50vh;
    a {
      padding-top: 30vh;
    }
  }
}

// Work
.Work {

  .App-header {
    min-height: 20vh;
  }
}

.Workplaces {
  display: flex;
  color: white;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  h2 {
    margin: 0;
  }
  h3 {
    margin: 0 0 12px 0;
  }

  .Workplace-details {
    margin: 0 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: left;

    @media screen and (max-width: 768px) {
      margin: 12px;
    }
  }

}
@media print {
  body, html {
    background: white;
  }
}

.Resume {

  .App-main {
    display: flex;
    max-width: 800px;
    margin: auto;
    text-align: left;
    @media screen and (max-width: 768px) {
      flex-direction: column;

      .left {
        padding: 0 24px 24px;
      }
    }
    .left {
      min-width: 25%;
      background: #6c94ab;
      padding-left: 12px;
      box-sizing: border-box;
      h1 {
        margin-bottom: 0px;
        li {
          margin-left: 4px;
        }
      }
      h2 {
        font-size: 1.2rem;
        margin-top: 0px;
      }
      h3 {
        margin: 0;
        font-weight: normal;
        font-size: .9rem;
      }
      h4 {
        margin-bottom: 0;
      }
      ul {
        margin-top: 0;
        font-size: .9rem;
      }
      div {
      }
    }
    .right {
      color: white;
      margin: 0 24px;
    }
  }

  .App-header {
    display: none;
  }


  @media print {
    body * {
      visibility: hidden;
    }
    .App-main, .App-main * {
      visibility: visible;
    }
    .App-main {
      position: absolute;
      left: 0;
      top: 0;
      background: white;
      color: black;

      .left {
        color: black;
        background: white;
        border-right: 1px solid lightgray;
        margin-left: 24px;
      }
      .right {
        color: black;
        background: white;
      }
    }
  }
}

// Projects

.PersonalProjects {
  color: white;
  
  .App-header {
    min-height: 20vh;
  }
  .Info {
    margin: 12px 0;
    font-size: .95rem;
  }
}

.Projects {
  display: flex;
  color: white;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
  h2 {
    margin: 0;
  }
  h3 {
    margin: 0 0 12px 0;
    margin-bottom: 0;
  }

  a {
    color: white;
    &:visited {
      color: white;
    }
    &:last-of-type {
      margin-bottom: 8px;
    }
  }

  .Project-details {
    margin: 0 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: left;

    @media screen and (max-width: 768px) {
      margin: 12px;
    }
  }

}