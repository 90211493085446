@import url("https://fonts.googleapis.com/css?family=Crimson+Text|Montserrat:400,600&display=swap");
@font-face {
  font-family: "Coldiac";
  src: url("./fonts/Coldiac.otf") format("opentype"),
    url("./fonts/Coldiac.ttf") format("truetype");
}

h1 {
  font-family: 'Coldiac';
  font-weight: normal;
}

html,
body {
  background-color: #282c34;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
